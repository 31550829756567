import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        token: '',
        tokens: [],
        user: {},
        accounts: [],
        requestConfig: { params: { table: 'clients' } },
    },
    getters: {
        isAuthenticated: (state) => !!(state.user && state.token),
        user: (state) => state.user,
        accounts: (state, getters) => (getters.isAuthenticated ? state.accounts : []),
        token: (state) => state.token,
        rights: (state, getters) => (mod, permission) => {
            if (!getters.isAuthenticated || !state.user.rights_processed) {
                return false;
            }
            try {
                return (
                    state.user.rights_processed[mod]
                    && state.user.rights_processed[mod].enabled
                    && (!permission || state.user.rights_processed[mod].permissions[permission])
                );
            } catch (err) {
                return false;
            }
        },
    },
    mutations: {
        login(state, { user, token = '', users } = {}) {
            state.user = user || {};
            if (token || !user) {
                state.token = token;
            }

            if (users || !user) {
                state.accounts = users;
                const tokens = (users || []).reduce((acc, cur) => {
                    acc.push(...cur.map((it) => it.token));
                    return acc;
                }, []);
                state.tokens = tokens;
                if (tokens && tokens.length) {
                    const data = { tokens, token };
                    localStorage.setItem('authentication_v2', JSON.stringify(data));
                } else {
                    localStorage.removeItem('authentication_v2');
                }
            }
        },

        setRights(state, rights_processed = {}) {
            state.user.rights_processed = rights_processed;
        },
    },
    actions: {
        login({ dispatch, state }, data) {
            // data = email, password, token
            return new Promise((resolve, reject) => {
                Vue.prototype.$http.post('login/verify/', data, state.requestConfig)
                    .then((r) => dispatch('loginSuccess', { r, trigger: 'verify' }))
                    .then(resolve)
                    .catch((err) => {
                        console.error(err);
                        reject(err);
                    });
            });
        },

        loginWithProvider(context, { url, returnPath = window.location.href }) {
            const returnUrl = encodeURIComponent(returnPath);
            const to = `${url}${returnUrl}`;
            console.debug('LoginWithProvider:', to);
            window.location.href = to;
        },

        loginTwoFactor({ dispatch, state }, data) {
            // data = email, password, code, user
            return new Promise((resolve, reject) => {
                Vue.prototype.$http.post('login/twofactor/', data, state.requestConfig)
                    .then((r) => dispatch('loginSuccess', { r, trigger: 'twofactor' }))
                    .then(resolve)
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    });
            });
        },

        loginSuccess({ dispatch }, { r, trigger }) {
            return new Promise((resolve, reject) => {
                if (r.data.status !== 'success') {
                    reject(new Error('Error in login response', r));
                } else if (trigger !== 'twofactor' && r.data.twofactor) {
                    resolve(r.data);
                } else if (r.data.users && r.data.users.length) {
                    dispatch('setSession', r.data).then((res) => resolve(res));
                } else {
                    reject(new Error('No users in response', r));
                }
            });
        },

        passReset({ state }, { email }) {
            return new Promise((resolve, reject) => {
                Vue.prototype.$http.post('login/forgotpassword/', { email }, state.requestConfig)
                    .then((r) => {
                        if (!r.data || r.data.status !== 'success') {
                            throw new Error('Response error');
                        }
                        resolve({ status: 'success' });
                    })
                    .catch((err) => {
                        try {
                            const data = JSON.parse(err.data);
                            if (data.code && data.code === 44) {
                                reject(new Error('unsupported'));
                            } else {
                                console.error(err);
                                reject(new Error('other error'));
                            }
                        } catch (er) {
                            console.error(err);
                            reject(new Error('unknown_account'));
                        }
                    });
            });
        },

        logout({ commit, dispatch, state }) {
            dispatch('deleteSession', { tokens: state.tokens });
            dispatch('SmartLockLogout');
            commit('login');
        },

        setSession({ commit }, data) {
            data.users.forEach((user) => {
                user.sessions.forEach((session) => {
                    Object.assign(session.user, user.user);
                });
            });

            const users = data.users.map((it) => it.sessions);
            if (users.length === 1 && users[0].length === 1) {
                commit('login', { user: users[0][0].user, token: users[0][0].token, users });
                return new Promise((resolve) => resolve({ user: users[0], data, users }));
            }

            // When multiuser, check if storedtoken matches a session to restore.
            const storedData = JSON.parse(localStorage.getItem('authentication_v2'));
            const storedToken = storedData && storedData.token ? storedData.token : false;
            if (storedToken) {
                for (let i = 0; i < users.length; i += 1) {
                    for (let j = 0; j < users[i].length; j += 1) {
                        if (users[i][j].token === storedToken) {
                            commit('login', {
                                user: users[i][j].user,
                                token: users[i][j].token,
                                users,
                            });
                            return new Promise((resolve) => resolve({
                                user: users[i],
                                data,
                                users,
                            }));
                        }
                    }
                }
            }
            return new Promise((resolve) => resolve({ data, users }));
        },

        restoreSession({ dispatch, state }, data = JSON.parse(localStorage.getItem('authentication_v2'))) {
            return new Promise((resolve, reject) => {
                if (data && data.tokens) {
                    Vue.prototype.$http.post('login/retrieve/', { tokens: data.tokens }, state.requestConfig)
                        .then((r) => dispatch('loginSuccess', { r, trigger: 'verify' }))
                        .then(resolve)
                        .catch((err) => {
                            dispatch('logout');
                            console.error(err);
                            reject(err);
                        });
                } else {
                    reject(new Error('no token'));
                }
            });
        },

        deleteSession({ state }, { tokens }) {
            Vue.prototype.$http.delete('login/verify/', { tokens }, state.requestConfig)
                .then((r) => {
                    const url = r.data.logout_url;
                    if (url) {
                        window.location.href = url;
                    }
                });
        },

        SmartLockGet() {
            if (window.PasswordCredential || window.FederatedCredential) {
                return navigator.credentials.get({ password: true });
            }
            return Promise.reject(new Error('unsupported'));
        },

        SmartLockSave(context, { id, password }) {
            if (window.PasswordCredential || window.FederatedCredential) {
                // eslint-disable-next-line
                const cred = new PasswordCredential({ id, password });
                navigator.credentials.store(cred);
            }
        },

        SmartLockLogout() {
            // Google Smart Lock - Prevent automatic re-sign-in
            if (window.PasswordCredential || window.FederatedCredential) {
                navigator.credentials.preventSilentAccess();
            }
        },
    },
};
