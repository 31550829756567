<template>
    <div class="button relative -icon -s weight-b languageselect">
        <img :src="'/img/flag_' + currentLanguage + '.svg'" class="flag" :title="currentLanguage" />

        <div class="languageselect-content absolute">
            <button v-for="lang in otherLanguages" @click="setLang(lang)" :key="'lang_' + lang.id">
                {{ lang.name }}
                <img :src="'/img/flag_' + lang.id + '.svg'" class="flag ml-r" :title="lang.name" />
            </button>
        </div>
    </div>

</template>

<script>
export default {
    name: 'LanguageSwitch',
    computed: {
        currentLanguage() {
            return this.$i18n.locale;
        },

        otherLanguages() {
            const list = this.$store.getters.languages;
            return list.filter((it) => it.id !== this.currentLanguage);
        },
    },
    methods: {
        setLang(lang) {
            const nextRoute = this.$router.currentRoute;
            nextRoute.params.locale = lang.id;
            this.$router.push(nextRoute);
        },
    },
};
</script>

<style>
    .flag {
        width: 1.8rem;
        border: 0.1rem solid var(--color-border)
    }
    .languageselect-content {
        top: 100%;
        right: 0;
        display: none;
    }
    .languageselect:focus .languageselect-content,
    .languageselect:focus-within .languageselect-content,
    .languageselect:target .languageselect-content,
    .languageselect:hover .languageselect-content {
        display: block;
    }
    .languageselect-content button {
        color: var(--color-primary);
        justify-content: space-between;
        align-items: center;
        font-size: var(--size-6);
        padding: 0;
        line-height: 2.4;
        height: unset;
        position: relative;
        border: unset;
        border-radius: unset;
        width: 100%;
        margin: 0;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding-left: var(--size-m);
        padding-right: var(--size-m)

    }
</style>
