<template>
    <footer class="bg-primary">
        <div class="c w-xxl">
            <div class="footer__card bg-accent-3 rounded-s overflow-hidden">
                <Newsletter v-if="data.newsletter_listid" />

                <div class="footer__stock bg-accent color-contrast flex justify-center" v-if="data.stock_download">
                    <div class="flex w-12 px-xl m-up:w-10 m-up:px-0 flex-wrap align-center justify-between xs:py-xxl s-up:py-l">
                        <div class="xs:mb-l">
                            <h3>{{ data['stock_title_' + lang] }}</h3>
                            <div class="color-primary">{{ data['stock_subtitle_' + lang] }}</div>
                        </div>
                        <div>
                            <a :href="cdnurl(data.stock_download)" download class="button">{{ $t('footer.actions.download') }}</a>
                            <router-link :to="lang + '/' + 'downloadStock'" v-if="data.stock_download_auth" class="button">{{ $t('footer.actions.download_with_prices') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="color-contrast footer__bottom pt-xl pb-s leading-m color-primary l-up:px-l">
                <strong>{{ settings.company_name }}</strong>
                <div class="footer__bottom flex flex-wrap columns text-nowrap">
                    <div class="pr-xl mr-xl mb-l col">
                        <address v-html="formattedAddress" />
                    </div>
                    <div class="pr-xl mr-xl mb-l col">
                        <a :href="'mailto:' + settings.company_email" class="color-primary hover:color-secondary">{{ settings.company_email }}</a>
                        <a v-if="settings.phone_clim" :href="'tel:' + settings.phone_clim" class="color-primary hover:color-secondary">Clim: {{ settings.phone_clim }}</a>
                        <a v-if="settings.phone_leon" :href="'tel:' + settings.phone_leon" class="color-primary hover:color-secondary">Leon: {{ settings.phone_leon }}</a>
                    </div>
                    <div v-for="(column, index) in pages" :key="index" class="pr-xl mr-xl mb-l col">
                        <router-link
                            v-for="link in column"
                            :key="link.title"
                            :to="link.route"
                            class="color-primary hover:color-secondary"
                        >
                            {{ link.title }}
                        </router-link>
                    </div>
                    <div class="flex-grow" />
                    <div>
                        <br /><br />
                        <a href="https://projectfive.nl" rel="nofollow noreferrer" class="hover:opacity-50 flex align-center size-6 color-tertiary">
                            {{ $t('footer.site_by') }}
                            <img src="/img/logo_projectfive.svg" alt="Project Five Logo" title="Project Five" class="w-24 h-24 ml-s" />
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
import Newsletter from '@/components/Newsletter.vue';

export default {
    name: 'Footer',
    components: {
        Newsletter,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        lang() {
            return this.$i18n.locale;
        },

        settings() {
            if (this.data && this.data.settings) {
                return this.data.settings[0];
            }
            return {};
        },

        formattedAddress() {
            if (this.settings.company_address) {
                return this.settings.company_address.formatted.replace(/,/g, '<br />');
            }
            return '';
        },

        pages() {
            const lang = this.lang;

            return [[
                { title: this.$t('products.menu'), route: `/${lang}/#assortiment` },
                { title: this.$t('about.menu'), route: `/${lang}/over-ons` },
                { title: this.$t('contact.menu'), route: `/${lang}/contact` },
            ], [
                { title: this.$t('footer.terms_and_conditions'), route: `/${lang}/algemene-voorwaarden` },
                { title: this.$t('footer.privacy'), route: `/${lang}/privacy-policy` },
            ]];
        },
    },
};
</script>

<style>
.footer__bottom a { display: flex; }
.footer__bottom a:hover { color: var(--color-tertiary); }

footer {
    margin-top: calc(3 * var(--size-xxl));
}
.footer__card {
    margin-top: calc(-1 * var(--size-xl));
}
</style>
