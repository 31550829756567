<template>
    <nav class="pt-s pb-r" :class="{ '-toggled': toggled }">
        <div class="c w-xxl">
            <div class="flex justify-start m-down:flex-col m-down:align-start l-up:align-center">
                <router-link :to="'/' + lang" class="logo mr-l">
                    <img src="/img/logo_kwekerij_meuter.png" alt="Kwekerij Meuter Logo" title="Kwekerij Meuter Logo" />
                    <h1 hidden>{{ data.settings && data.settings[0].company_name }}</h1>
                </router-link>

                <div class="nav__mobile-toggle absolute flex align-center l-up:hide">
                    <Button @click="toggled = !toggled" class="bg-accent">
                        {{ $t('app.menu') }}
                    </Button>

                    <LanguageSwitch />
                </div>

                <ul
                    id="nav"
                    class="flex flex-grow text-nowrap m-down:flex-col m-down:align-stretch m-down:text-center l-up:align-center"
                >
                    <li v-for="page in pages" :key="'page_' + page.title">
                        <router-link v-if="page.route" :to="page.route">
                            {{ page.title }}
                        </router-link>
                        <template v-else-if="page.children && page.children.length">
                            <a href="javascript:void(0);">
                                {{ page.title }}<span class="color-secondary">V</span>
                            </a>
                            <ul>
                                <li v-for="subpage in page.children" :key="'page_' + subpage.title">
                                    <router-link v-if="subpage.route" :to="subpage.route">
                                        {{ subpage.title }}
                                    </router-link>
                                </li>
                            </ul>
                        </template>
                    </li>
                    <li class="flex-grow" />
                    <li v-if="data.newsletter_listid" class="flex align-center justify-center m-down:mt-xl">
                        <div class="size-7 color-tertiary l:hide">
                            {{ data['newsletter_short_title_' + lang] }}
                        </div>
                        <Button to="#newsletter" class="bg-accent ml-r">
                            {{ $t('newsletter.menu') }}
                        </Button>
                    </li>
                    <li v-if="$store.getters['Auth/isAuthenticated']" :title="$t('login.actions.logout')">
                        <button type="button" class="button bg-accent-3 hover:bg-accent -icon" @click="$store.dispatch('Auth/logout')">
                            <img src="/img/icon_lock.svg" class="w-16 h-16" />
                        </button>
                    </li>
                    <li class="flex justify-center m-down:hide">
                        <LanguageSwitch />
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import LanguageSwitch from '@/components/LanguageSwitch.vue';

export default {
    name: 'Navigation',
    components: {
        LanguageSwitch,
    },
    props: {
        servicePagesWithoutSolar: Array,
    },
    data() {
        return {
            toggled: false,
        };
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        lang() {
            return this.$i18n.locale;
        },

        pages() {
            const lang = this.lang;
            return [
                { title: this.$t('products.menu'), route: `/${lang}/#assortiment` },
                { title: this.$t('about.menu'), route: `/${lang}/over-ons` },
                { title: this.$t('contact.menu'), route: `/${lang}/contact` },
            ];
        },
    },
    watch: {
        '$route.name': {
            handler() { this.toggled = false; },
        },
    },
};
</script>

<style>
    nav { top: 0; z-index: 50; width: 100%; transition: all .3s ease; }
    nav .logo {
        width: 55%;
        max-width: 27rem;
    }
        nav .logo img {
            width: 100%;
            display: block;
        }

        #nav > li { position: relative; }
        #nav > li:not(:hover):not(:focus) > ul { display: none; }
        #nav ul > li > * {
            padding: var(--size-s) var(--size);
            display: block;
        }

        .nav__mobile-toggle {
            right: var(--container-gap);
            top: 0.8rem;
        }

    @media (min-width: 62em) {
        #nav > li > *:not(.button) {
            padding: 0 var(--size);
        }
        #nav ul {
            top: 100%;
            position: absolute;
            z-index: 10;
            left: 0;
            border-radius: var(--radius-m);
            background: var(--color-card);
            box-shadow: var(--shadow-l);
            padding: var(--size-s) 0 !important;
        }
    }
    @media (max-width: 62em) {
        nav.-toggled { background: var(--color-card); box-shadow: var(--shadow-l); }
        #nav { width: 100%; }
        nav:not(.-toggled) #nav {display: none; }
        #nav > li > *:not(.button) {
            padding: var(--size-r) var(--size);
            display: block;
        }
        #nav { margin: var(--size-l) 0 !important; }
        #nav ul { font-size: var(--size-6); }
        #nav > li.input-group { align-self: center; margin-top: var(--size); }
    }
</style>
