<template>
    <div class="footer__newsletter bg-accent-3 flex justify-center py-xxl overflow-hidden relative" id="newsletter">
        <div class="w-12 px-xl m-up:w-8 l-up:w-6 m-up:py-xxl relative z-5">
            <div class="w-10 xl:w-8">
            <form v-if="state === 'default'" validate @submit.prevent="onSubmit">
                <h2>{{ data['newsletter_title_' + lang] }}</h2>
                <div class="color-secondary">
                    {{ data['newsletter_text_' + lang] }}
                </div>
                <div class="flex flex-wrap align-center mt-xl">
                    <div class="m-down:w-12 m-down:mb-r l-up:mr-r">
                        <input type="email" class="-l" autocomplete="email" v-model="fields.EMAIL" :placeholder="$t('contact.fields.email')" required />
                    </div>
                    <button class="bg-accent -l m-down:w-12" type="submit">{{ $t('newsletter.actions.subscribe') }}</button>
                </div>
            </form>
            <div v-else-if="state === 'error'">
                Error while submitting.
            </div>
            <div v-else-if="state === 'success'">
                {{ data['newsletter_success_text_' + lang] }}
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newsletter } from 'p5-helpers';

export default {
    name: 'Newsletter',
    data() {
        return {
            loading: false,
            state: 'default',
            error: null,
            handler: undefined,
            watcher: undefined,
            fields: {
                EMAIL: '',
                LANG: this.$i18n.locale,
            },
        };
    },
    created() {
        this.watcher = this.$watch('data', this.onDataUpdate, { immediate: true });
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        lang() {
            return this.$i18n.locale;
        },
    },
    methods: {
        onDataUpdate(val) {
            if (val) {
                this.handler = new newsletter.Mailchimp({
                    dataCenter: this.data.newsletter_datacenter,
                    userID: this.data.newsletter_userid,
                    listID: this.data.newsletter_listid,
                });
                this.$nextTick(() => {
                    this.watcher();
                    this.watcher = undefined;
                });
            }
        },

        onSubmit() {
            this.loading = true;
            this.handler.subscribe(this.fields)
                .then(() => {
                    this.state = 'success';
                    this.resetForm();
                }).catch((err) => {
                    this.state = 'error';
                    this.error = err;
                    console.error(err);
                }).finally(() => {
                    this.loading = false;
                });
        },

        resetForm() {
            this.fields.email = '';
        },
    },
};
</script>

<style>
.footer__newsletter::before, .footer__newsletter::after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: 15%;
    height: 100%;
    background-image: url('/img/leaves.svg');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
}
    .footer__newsletter::after {
        top: auto;
        left: auto;
        right: -3%;
        bottom: -4rem;
        width: 10%;
        background-position: bottom left;
        transform: scaleX(-1);
    }

    @media (max-width: 48em) {
        .footer__newsletter::before { display: none; }
        .footer__newsletter::after {
            width: 25%;
            right: -1rem;
            bottom: -1rem;
        }
    }

</style>
