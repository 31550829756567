import Vue from 'vue';

// Plugins
import axios from 'axios';
import VueMeta from 'vue-meta';
import webdata from '@/assets/helpers/mixin.webdata';

// Global Components
import Actions from '@/components/ui/Actions.vue';
import Button from '@/components/ui/Button.vue';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

global.router = router;

// Setup axios
const instance = axios.create({
    baseURL: `https://${process.env.VUE_APP_API_URL}`,
    headers: {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
        namespace: `${process.env.VUE_APP_API_NAMESPACE}`,
    },
    timeout: 15000,
});
Vue.prototype.$http = instance;

Vue.mixin(webdata);
Vue.use(VueMeta, {
    keyName: 'meta',
    refreshOnceOnNavigation: true,
});

Vue.component('Actions', Actions);
Vue.component('Button', Button);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
