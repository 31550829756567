<template>
  <div id="app" class="flex flex-col align-stretch">
      <Navigation />
    <main>
        <router-view/>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Navigation from '@/components/Navigation.vue';

export default {
    name: 'App',
    components: {
        Navigation, Footer,
    },
    created() {
        if (window.PRERENDER_INJECTED) {
            this.$store.commit('setData', window.PRERENDER_INJECTED.texts);
        } else {
            this.$store.dispatch('get', 'items/texts/1').then((r) => {
                if (r.settings && r.settings[0]) {
                    const settings = r.settings[0];
                    const lang = this.$i18n.locale;
                    this.$store.commit('setMeta', {
                        from: 'config',
                        data: {
                            descriptionDefault: settings[`meta_description_${lang}`],
                            titleDefault: settings[`meta_title_${lang}`],
                            keywordsDefault: (Array.isArray(settings[`meta_keywords_${lang}`]) ? settings[`meta_keywords_${lang}`] : []).map((it) => it.name).join(', '),
                        },
                        schemas: [{
                            '@context': 'https://schema.org',
                            '@type': 'Organization',
                            name: settings.company_name,
                            description: settings[`meta_description_${lang}`],
                            address: settings.company_address && settings.company_address.parts ? {
                                '@type': 'PostalAddress',
                                addressLocality: settings.company_address.parts.city,
                                postalCode: settings.company_address.parts.zip,
                                streetAddress: `${settings.company_address.parts.street} ${settings.company_address.parts.number}`,
                            } : {},
                            email: settings.company_email,
                            telephone: settings.phone_clim,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_kwekerij_meuter.png`,
                            slogan: settings[`meta_title_${lang}`],
                            image: this.cdnurl(r.home_image),
                            url: `https://${process.env.VUE_APP_URL}`,
                        }],
                    });
                }
            });

            this.$store.dispatch('Auth/restoreSession').catch(() => {});
        }
    },
    meta() {
        return this.$store.getters.meta;
    },
};
</script>

<style>
@import '~p5-spark-css/dist/forms.css';
@import '~p5-spark-css/dist/vue.css';
@import '~p5-spark-css/dist/base.css';
@import 'assets/css/application.css';
</style>
