const prerenderSettings = {
    remoteData: {
        texts: '/items/texts/1',
    },
    redirects: {
        default: ['/*   /index.html 200'],
    },
    languages: [
        'nl', 'en', 'de', 'ru', 'pl',
    ],
    sitemap: true,
};

const routes = [
    {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            guest: true,
            prerender: true,
            schema: (data, context) => [
                {
                    '@context': 'https://schema.org',
                    '@type': 'ItemList',
                    name: 'Assortiment',
                    itemListElement: [
                        ...data.map((it, index) => ({
                            '@type': 'ListItem',
                            position: index + 1,
                            url: `/products/${it.id}/${context.kebabcase(it.name)}`,
                        })),
                    ],
                },
            ],
        },
    },
    {
        path: 'assortiment',
        redirect: '/#assortiment',
    },
    {
        path: 'contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue'),
        meta: {
            guest: true,
            prerender: true,
        },
    },
    {
        path: 'products/:id/:slug?',
        name: 'Assortiment',
        component: () => import(/* webpackChunckName: "products" */ '@/views/Products.vue'),
        props(route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
        meta: {
            guest: true,
            article: {
                endpoint: 'items/products/?category=[id]',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'ItemList',
                        name: 'Projecten',
                        itemListElement: [
                            ...data.map((it, index) => ({
                                '@type': 'ListItem',
                                position: index + 1,
                                url: `/product/${it.id}/${context.kebabcase(it.name)}`,
                            })),
                        ],
                    },
                ],
            },
            prerender: {
                url: 'items/products_categories/',
                path: 'products/[id]/[name|kebabcase]',
            },
        },
    },
    {
        path: 'product/:id/:slug?',
        name: 'Product',
        component: () => import(/* webpackChunckName: "products" */ '@/views/Product.vue'),
        props(route) {
            const props = { ...route.params };
            props.id = +props.id;
            return props;
        },
        meta: {
            guest: true,
            article: {
                endpoint: 'items/products/[id]',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Product',
                        name: data.name,
                        description: data[`text_${context.lang}`].replace(/(<([^>]+)>)/gi, ''),
                        image: context.cdnurl(data.image),
                        brand: process.env.VUE_APP_NAME,
                        manufacturer: process.env.VUE_APP_NAME,
                        model: data.name,
                        sku: data.id,
                        category: data.category.name,
                    },
                ],
            },
            prerender: {
                url: 'items/products/',
                path: 'product/[id]/[name|kebabcase]',
                linkOnly: true,
            },
        },
    },
    {
        path: 'over-ons',
        name: 'Over Ons',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            guest: true,
            article: {
                endpoint: 'items/about/1',
            },
            prerender: {
                url: 'items/about/1',
            },
        },
    },
    {
        path: 'privacy-policy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            guest: true,
            article: {
                endpoint: 'items/privacy/1',
            },
            prerender: {
                url: 'items/privacy/1',
            },
        },
    },
    {
        path: 'algemene-voorwaarden',
        name: 'Algemene Voorwaarden',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            guest: true,
            article: {
                endpoint: 'items/terms/1',
            },
            prerender: {
                url: 'items/terms/1',
            },
        },
    },
    {
        path: 'loggedout',
        name: 'loggedout',
        component: () => import('@/views/Loggedout.vue'),
        meta: {
            guest: true,
        },
    },
    {
        path: 'downloadStock',
        name: 'downloadStock',
        component: () => import('@/views/DownloadStock.vue'),
        meta: {
            guest: false,
        },
    },
    {
        path: 'login',
        name: 'Login',
        alias: '/logout',
        component: () => import('@/views/Login.vue'),
        meta: {
            guest: true,
            name: 'Login',
        },
    },
];

exports.routes = routes;
exports.prerenderSettings = prerenderSettings;
