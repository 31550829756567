import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from '@/router/routes';
import i18n from '@/i18n';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:locale(nl|en|de|ru|pl)',
            component: {
                render: (h) => h('router-view'),
            },
            children: routes.routes,
            meta: {
                guest: true,
            },
        },
        {
            path: '*',
            name: '404',
            props: true,
            component: () => import(/* webpackChunkName: "e404" */ '@/views/Error404.vue'),
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        }
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    i18n.locale = to.params.locale || i18n.locale || 'nl';
    if (!to.params.locale && (to.path === '/' || to.name !== '404')) {
        if (from.params.locale) {
            next({ path: `/${from.params.locale}${to.fullPath}`, params: { langRedirected: true } });
        } else {
            next({ path: `/${i18n.locale}${to.fullPath}`, params: { langRedirected: true } });
        }
    } else if (!to.matched.length) {
        next({
            name: '404',
            params: { error: to.path, locale: i18n.locale },
        });
    } else if (!to.matched.every((page) => page.meta.guest) && !store.getters['Auth/isAuthenticated']) {
        next({ path: `${i18n.locale}/login`, query: { redirect: to.path } });
    } else {
        store.commit({ type: 'setMeta', from: 'router', data: to });
        next();
    }
});

router.onError((e) => {
    console.log('on error');
    router.replace({ name: '404', params: { error: e } }).catch(() => {});
});

export default router;
